import { useState, useEffect } from 'react';

import AlarmTime from '../alarm-time/alarm-time.component';

import './box-edit-form.styles.scss';

const BoxEditForm = (props) => {

    const { setIsEditModalOpen, boxNumber, boxInfo, handleBoxDataSave } = props;

    const [editType, setEditType] = useState(boxInfo.medicineName ? null : "hard");
    const [medicineName, setMedicineName] = useState(boxInfo.medicineName);
    const [quantity, setQuantity] = useState(boxInfo.quantity);
    const [dose, setDose] = useState(boxInfo.dose);
    const [alarms, setAlarms] = useState(boxInfo.alarms);
    const [medicineNameInvalid, setMedicineNameInvalid] = useState(null);
    const [quantityInvalid, setQuantityInvalid] = useState(null);
    const [doseInvalid, setDoseInvalid] = useState(null);

    useEffect( () => {
        setMedicineNameInvalid(null);
    }, [medicineName]);
    useEffect( () => {
        setQuantityInvalid(null);
    }, [quantity]);
    useEffect( () => {
        setDoseInvalid(null);
    }, [dose]);
    useEffect( () => {
        if(!boxInfo.medicineName){
            if(editType !== "hard"){
                setEditType("hard");
            }
        }else{
            setMedicineName(boxInfo.medicineName);
            setQuantity(boxInfo.quantity);
            setDose(boxInfo.dose);
            setAlarms(boxInfo.alarms);
            setMedicineNameInvalid(null);
            setQuantityInvalid(null);
            setDoseInvalid(null);
        }
    }, [editType]);

    const handleAlarmTimeChange = (index, value) => {
        let newAlarms = [...alarms];
        newAlarms[index] = value;
        setAlarms(newAlarms);
    }
    const handleAddAlarm = () => {
        const index = alarms.findIndex(alarm => alarm===0);
        if(index !== -1)
            handleAlarmTimeChange(index, -1440);
    }
    const handleSave = () => {
        if(!(editType==="soft" || editType==="hard")) return;
        if(editType==="soft" && medicineName!==boxInfo.medicineName) return;
        if(editType==="hard" && medicineName.length<1){
            setMedicineNameInvalid("required");
            return;
        } 
        if(medicineName.length > 30){
            setMedicineNameInvalid("length");
            return;
        }
        // REGEX EXPLAINATION - whitelist to allow certain characters
        // a-zA-Z0-9 lowercase, uppercase English letters and numbers
        // \u0900-\u097F Hindi Unicode
        // \u0980-\u09FF Bengali Unicode
        // special characters: space, underscore and minus allowed
        if(!((/^([a-zA-Z0-9\u0900-\u097F\u0980-\u09FF _.-]+)$/i).test(medicineName))){
            setMedicineNameInvalid("character");
            return;
        }
        if(quantity < 0){
            setQuantityInvalid("lt0");
            return;
        }
        if(quantity > 999){
            setQuantityInvalid("gt999");
            return;
        }
        if(dose < 0){
            setDoseInvalid("lt0");
            return;
        }
        if(dose > 9){
            setDoseInvalid("gt9");
            return;
        }
        handleBoxDataSave(boxNumber, medicineName, quantity, dose, alarms);
    }
    const handleRemove = () => {
        handleBoxDataSave(boxNumber, "", 0, 0, [0,0,0,0,0]);     //boxNumber, medicineName, quantity, dose, alarms
    }

    return(
        <div className="box-edit-form-modal-content">
            <h4>{`Edit Schedule - Box ${boxNumber}`}</h4>
            <hr/>
            <div className="box-edit-form-modal-body">
            {   
                !(editType==="soft" || editType==="hard" || editType==="reset")   ?
                    <>
                        <div className="edit-type-option" onClick={() => setEditType("soft")}>
                            <span className="material-icons-outlined">edit</span>
                            <p className="edit-type-option-text">Edit details for current pill</p>
                            <span className="material-icons-outlined">chevron_right</span>
                        </div>
                        <div className="edit-type-option" onClick={() => setEditType("hard")}>
                            <span className="material-icons-outlined">replay</span>
                            <p className="edit-type-option-text">Remove current pill and add a new pill</p>
                            <span className="material-icons-outlined">chevron_right</span>
                        </div>
                        <div className="edit-type-option" onClick={() => setEditType("reset")}>
                            <span className="material-icons-outlined">delete</span>
                            <p className="edit-type-option-text">Remove current pill</p>
                            <span className="material-icons-outlined">chevron_right</span>
                        </div>
                    </>
                :
                    (editType==="reset")    ?
                        <div className="reset-confirm-form">
                            <p>The current pill and its schedule will be removed.</p>
                            <h5>Are you sure to continue?</h5>
                        </div>
                    :
                        <>
                            <div className="box-edit-form-group">
                                <label className="box-edit-form-group-label">Medicine Name</label>
                                <div className="box-edit-form-group-input">
                                    {
                                        (editType==="soft") &&
                                        <input 
                                            className={`${medicineNameInvalid && 'invalid'} disabled`}
                                            type="text"
                                            value={medicineName}
                                            disabled
                                        />
                                    }
                                    {
                                        (editType==="hard") &&
                                        <input 
                                            className={medicineNameInvalid && 'invalid'}
                                            type="text"
                                            value={medicineName}
                                            onChange={ e => setMedicineName(e.target.value) }
                                        />
                                    }
                                    <h6 className="box-edit-form-invalid-warning">
                                        {
                                            {
                                                'required': "Medicine Name can not be empty.",
                                                'length': "Medicine Name can have a maximum of 30 characters.",
                                                'character': "Medicine Name can have only letters, numbers, spaces and underscore."
                                            }[medicineNameInvalid]
                                        }
                                    </h6>
                                </div>
                            </div>
                            <div className="box-edit-form-group">
                                <label className="box-edit-form-group-label">Pill Quantity</label>
                                <div className="box-edit-form-group-input">
                                    <button
                                        className={`${quantity<=0 && 'disabled'} decrement`}
                                        onClick={ () => {
                                            if(quantity>0)
                                            setQuantity(quantity-1)
                                        }}
                                    >
                                        <span className="material-icons-outlined">remove</span>
                                    </button>
                                    <input
                                        className={quantityInvalid && 'invalid'} 
                                        type="number"
                                        autoComplete="off"
                                        value={quantity}
                                        onChange={ e => {
                                            if(!isNaN(e.target.valueAsNumber)){
                                                setQuantity(e.target.valueAsNumber);
                                            }else{
                                                setQuantity(0);
                                            }
                                        }}
                                    />
                                    <button
                                        className={`${quantity>=999 && 'disabled'} increment`}
                                        onClick={ () => {
                                            if(quantity<999)
                                            setQuantity(quantity+1)
                                        }}
                                    >
                                        <span className="material-icons-outlined">add</span>
                                    </button>
                                    <h6 className="box-edit-form-invalid-warning">
                                        {
                                            {
                                                'lt0': "Quantity can not be less than 0.",
                                                'gt999': "Quantity can not be greater than 999."
                                            }[quantityInvalid]
                                        }
                                    </h6>
                                </div>
                            </div>
                            <div className="box-edit-form-group">
                                <label className="box-edit-form-group-label">Pill Dosage</label>
                                <div className="box-edit-form-group-input">
                                    <button
                                        className={`${dose<=0 && 'disabled'} decrement`}
                                        onClick={ () => {
                                            if(dose>0)
                                            setDose(dose-1)
                                        }}
                                    >
                                        <span className="material-icons-outlined">remove</span>
                                    </button>
                                    <input
                                        className={doseInvalid && 'invalid'} 
                                        type="number"
                                        autoComplete="off"
                                        value={dose}
                                        onChange={ e => {
                                            if(!isNaN(e.target.valueAsNumber)){
                                                setDose(e.target.valueAsNumber);
                                            }else{
                                                setDose(0);
                                            }
                                        }}
                                    />
                                    <button
                                        className={`${dose>=9 && 'disabled'} increment`}
                                        onClick={ () => {
                                            if(dose<9)
                                            setDose(dose+1)
                                        }}
                                    >
                                        <span className="material-icons-outlined">add</span>
                                    </button>
                                    <h6 className="box-edit-form-invalid-warning">
                                        {
                                            {
                                                'lt0': "Dose can not be less than 0.",
                                                'gt9': "Dose can not be greater than 9."
                                            }[doseInvalid]
                                        }
                                    </h6>
                                </div>
                            </div>
                            <div className="box-edit-form-group">
                                <label className="box-edit-form-group-label">Alarms</label>
                                <div className="box-edit-form-group-alarms">
                                        {alarms.map( (alarm, index) => {
                                            if(alarm !== 0 )
                                                return(<AlarmTime key={index} index={index} alarmTime={alarm} handleAlarmTimeChange={handleAlarmTimeChange} />)
                                            else
                                                return(<div key={index} style={{display: "none"}}/>)
                                        })}
                                        {
                                            alarms.includes(0) &&
                                            <div className="add-alarm-button" onClick={handleAddAlarm}>
                                                <span className="material-icons-outlined">notification_add</span>
                                                Add Alarms
                                            </div>
                                        }
                                </div>
                            </div>
                        </>
            }
            </div>
            <hr/>
            <div className="box-edit-form-modal-footer">
                {
                    (boxInfo.medicineName)
                    ?
                        !(editType==="soft" || editType==="hard" || editType==="reset")   ?
                            <button className="button-cancel" onClick={() => setIsEditModalOpen(false)}><h4>Cancel</h4></button>
                        :
                            <>
                                <div>
                                    <button 
                                        className="button-back" 
                                        onClick={() => setEditType(null)}
                                    >
                                        <h4>Back</h4>
                                    </button>
                                </div>
                                <div>
                                    <button className="button-cancel" onClick={() => setIsEditModalOpen(false)}><h4>Cancel</h4></button>
                                    {
                                        (editType==="reset")    ?
                                            <button className="button-remove" onClick={handleRemove}><h4>Remove</h4></button>
                                        :
                                            <button className="button-save" onClick={handleSave}><h4>Save</h4></button>
                                    }
                                </div>
                            </>
                    :
                        <>
                            <button className="button-cancel" onClick={() => setIsEditModalOpen(false)}><h4>Cancel</h4></button>
                            <button className="button-save" onClick={handleSave}><h4>Save</h4></button>
                        </>
                }
            </div>
        </div>
    )
}

export default BoxEditForm;