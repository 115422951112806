import { DateTime } from "luxon";

const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getDateFromAdherenceTimeString = (dateString, timezoneValue) => {
  const [day, month, year] = dateString.split('-').map( i => +i );
  const date = DateTime.fromObject({day, month, year}, {zone: timezoneValue});
  return date;
}

export const getNotificationDateString = (ISOtimestamp, deviceTimezone) => {
  const date = DateTime.fromISO(ISOtimestamp).setZone(deviceTimezone);
  return date.toLocaleString(DateTime.DATE_HUGE);
};

export const areEqualDates = ([...ISOtimestamps], deviceTimezone) => {
  try {
    const dates = [];
    ISOtimestamps.forEach((ISOtimestamp) => dates.push(DateTime.fromISO(ISOtimestamp).setZone(deviceTimezone)));
    dates.forEach((date) => {
      if (!date.isValid) throw "Invalid Date";
    });
    return dates.every((date) => date.day === dates[0].day);
  } catch (err) {
    return false;
  }
};

export const getTimeString = (ISOtimestamp, deviceTimezone) => {
  const date = DateTime.fromISO(ISOtimestamp).setZone(deviceTimezone);
  return date.toLocaleString(DateTime.TIME_24_SIMPLE);
};

export const getTimeAndDateString = (ISOtimestamp) => {
  const date = new Date(ISOtimestamp);
  return `${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)} ${("0" + date.getDate()).slice(-2)}-${monthNames[date.getMonth()].slice(0, 3)}-${date.getFullYear()}`;
};

export const getTimeStringfromAlarmCode = (code) => {
  return `${(
    "0" +
    (Math.floor(Math.abs(code) / 60) === 24
      ? 0
      : Math.floor(Math.abs(code) / 60))
  ).slice(-2)} : ${("0" + (Math.abs(code) % 60)).slice(-2)}`;
};

export const verifyPhoneNumber = (Num) => {
  let y = parseInt(Math.log10(parseInt(Num)) + 1);
  if (y != 12) {
    return false;
  }
  return true;
};

export const verifyABHANumber = (Num) => {
  let y = parseInt(Math.log10(parseInt(Num)) + 1);
  if(Num.length  ==0 || y == 14){
    return true;
  }else{
    return false;
  }
};

export const convertedOffset=(value)=>{
  value *= 60;
  return value;
}