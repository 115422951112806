import { useState, useRef, useContext } from 'react';
import { Outlet, useResolvedPath, useMatch } from 'react-router-dom';

import { Context } from '../../context/context';
import useWindowSize from '../../hooks/useWindowSize';

import Header from '../header/header.component';
import SideNav from '../side-nav/side-nav.component';

import './page-layout.styles.scss';

const PageLayout = (props) => {
    // state
    const [showSideNav, setShowSideNav] = useState(false);

    // state
    const { state, dispatch } = useContext(Context);
    const { user } = state;

    // router
    const resolved = useResolvedPath('/device/:deviceId/:page');
    const match = useMatch({ path: resolved.pathname, end:true });

    // refs
    const sideNavOverlayRef = useRef(null);

    // hooks
    const size = useWindowSize();

    const handleScroll = (e) => {
        dispatch({
            type: "SET_SCROLL_POSITION",
            payload: e.target.scrollTop,
        });
    }

    return(
        <div className="page-layout">
            {
                (!user)
                ?
                    <div className="spinner"></div>
                :
                    <>
                        {
                            (match && size.width>718) && 
                            <SideNav isOverlay={false} />
                        }
                        <div className="page-component" onScroll={handleScroll}>
                            <Header showMenuButton={(match && !(size.width>718)) ? true : false} setShowSideNav={setShowSideNav} />
                            <Outlet />
                        </div>
                        {
                            (match && !(size.width>718) && showSideNav) &&
                            <div 
                                className="page-nagivation-overlay"
                                ref={sideNavOverlayRef}
                                onClick={ (e) => {
                                    if(e.target == sideNavOverlayRef.current)
                                        setShowSideNav(false)
                                }}
                            >
                                <SideNav isOverlay={true} closeOverlay={() => setShowSideNav(false)} />
                            </div>
                        }
                    </>
            }
        </div>
    )
}

export default PageLayout;