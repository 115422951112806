import { useState } from 'react';

import './avatar.styles.scss';

const Avatar = (props) => {

    const [ imageStatus, setImageStatus ] = useState(null);

    const backgroundColour = () => {
        var colornames = ["emerald", "peter-river", "wisteria", "carrot", "alizarin", "turquoise", "midnight-blue"];
        if(props.children)
            return `avatar-${colornames[props.children[0].codePointAt()%colornames.length]}`;
        else
            return `avatar-${colornames[0]}`;
    }

    const nameToInitials = (fullName) => {
        const namesArray = fullName.split(' ');
        if (namesArray.length === 1) return `${namesArray[0].charAt(0).toUpperCase()}`;
        else return `${namesArray[0].charAt(0).toUpperCase()}${namesArray[namesArray.length - 1].charAt(0).toUpperCase()}`;
    }

    return(
        <div className={`avatar ${backgroundColour()}`}>
            {
                (imageStatus === 'loaded') 
                ?
                    <img 
                        className={`img-${imageStatus}`}
                        alt={props.alt}
                        src={props.src} 
                        referrerPolicy="no-referrer"
                    />
                :
                    <>
                        {props.children && nameToInitials(props.children)}
                        {props.src &&
                            <img 
                                onLoad={() => setImageStatus('loaded')}
                                style={{ display: 'none' }}
                                alt={props.alt} 
                                src={props.src} 
                                referrerPolicy="no-referrer"
                            />
                        }
                    </>
            }
        </div>
    )
}

export default Avatar;