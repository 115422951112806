import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { sendPasswordResetEmail } from "firebase/auth";

import { auth } from "../../firebase/firebase.utils";

import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";

import './forgot-password.styles.scss'

const ForgotPassword = (props) => {

    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // router
    const navigate = useNavigate();

    const handleSubmit = async(event) => {
        event.preventDefault();
        console.log(window.location.origin + '/signin');
        const toastId = toast.loading("Loading...");
        try {
            setIsLoading(true);
            await sendPasswordResetEmail(auth, email, {
                url: `${window.location.origin}/signin`,
            });
            toast.update(toastId, { render: 'An email has been sent to reset your password.', type: "success", isLoading: false, autoClose: 2000, delay: 100 });
            setIsLoading(false);
            // redirect
            navigate('/signin');
        } catch (err) {
            console.log(err);
            switch (err.code){
                case 'auth/user-not-found':
                    toast.update(toastId, { render: "The user does not exist. Please Sign Up.", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
                    break;
                default:
                    toast.update(toastId, { render: "Something went wrong", type: "error", isLoading: false, autoClose: 2000, delay: 100 });
            }
            setIsLoading(false);
        }
    }

    return(
        <div className="forgot-password-page-background">
            <div className="forgot-password-page-content">
                <h2 className="title">Reset Password</h2>
                <form onSubmit={handleSubmit}>
                    <FormInput type="email" handleChange={ e => setEmail(e.target.value.trim()) } value={email} label="Email" required/>
                    <CustomButton type="submit" id="forgot-password-button">Send Email</CustomButton>
                </form>
                {isLoading &&
                    <div className="disable-form" onClick={e => e.stopPropagation()}></div>
                }
            </div>
        </div>
    )
}

export default ForgotPassword;