import { getTimeString, getDateFromAdherenceTimeString } from "../../utilities/utils";

import "./dashboard-notifications-list.styles.scss";

const DashboardNotificationsList = ( props ) => {
    const {deviceNotifications, selectedDate, setSelectedDate} = props;
    
    return(
        <div className={`dashboard-notifications-list ${deviceNotifications ? '' : 'loading-skeleton'}`}>
            {deviceNotifications && selectedDate &&
                <>
                    <div className="dashboard-notifications-list-heading">
                        <button
                            className={`${selectedDate<=1 ? 'disabled' : ''}`}
                            onClick={ () => {
                                setSelectedDate(Math.max(selectedDate-1,1));
                            }}
                        >
                            <span className="material-icons-outlined">chevron_left</span>
                        </button>
                        <div className="selected-date">
                            <span className="relative-string">{`${getDateFromAdherenceTimeString(deviceNotifications.days[selectedDate-1].date, deviceNotifications.deviceTimezone.timezoneValue).toRelativeCalendar({ unit: "days"})},`}</span>
                            <span className="date-month">{`${getDateFromAdherenceTimeString(deviceNotifications.days[selectedDate-1].date, deviceNotifications.deviceTimezone.timezoneValue).toFormat('dd-MMM')}`}</span>
                            {/* TODO: Add a calendar icon with datepicker for selecting the date directly.
                            <button
                                onClick={ () => {
                                }}
                            >
                                <span className="material-icons-outlined">today</span>
                            </button> */}
                        </div>
                        <button
                            className={`${selectedDate>=14 ? 'disabled' : ''}`}
                            onClick={ () => {
                                setSelectedDate(Math.min(selectedDate+1, deviceNotifications.days.length));
                            }}
                        >
                            <span className="material-icons-outlined">chevron_right</span>
                        </button>
                    </div>
                    <div className="dashboard-notifications-list-content">
                    {
                        deviceNotifications.days[selectedDate-1].notifications.length === 0 ?
                            <p>No Notifications!</p>
                        :
                        deviceNotifications.days[selectedDate-1].notifications.map( notification => (
                            <div className="notification-item">
                                <div className="notification-item-time">{getTimeString(notification.timestamp, deviceNotifications.deviceTimezone.timezoneValue)}</div>
                                <div className={`notification-item-color ${notification.code[0]==='7' ? 'taken' : notification.code[0]==='6' ? 'missed' : ''}`} />
                                <div className="notification-item-message">
                                    <span>{notification.message}</span>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                </>
            }
        </div>
    )
}

export default DashboardNotificationsList;