import axios from 'axios';
import { useEffect, useContext, useState } from 'react';
import { useParams } from "react-router-dom";
import { Context } from "../../context/context";

import DashboardCarousel from '../../components/dashboard-carousel/dashboard-carousel.component';
import DashboardAlarms from '../../components/dashboard-alarms/dashboard-alarms.component';
import DashboardNotificationsList from '../../components/dashboard-notifications-list/dashboard-notifications-list.component';

import "./dashboard.styles.scss";

const Dashboard = (props) => {
    //state
    const [deviceInfo, setDeviceInfo] = useState(null);
    const [deviceNotifications, setDeviceNotifications] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);   // value will be index+1

    // state
    const { state, dispatch } = useContext(Context);
    const { user } = state;

    // router
    const { deviceId } = useParams();

    useEffect( async() => {
        await loadDeviceInfo();
        loadNotificationsInfo();
    }, []);

    const loadDeviceInfo = async() => {
        try {
            const {data} = await axios.get(`/app-api/device/${deviceId}/dashboard`);
            if(data) {
                setDeviceInfo(data);
            };
        } catch (err) {
            console.log(err);
        }
    }

    const loadNotificationsInfo = async() => {
        try {
            const {data} = await axios.get(`/app-api/device/${deviceId}/adherence`);
            setDeviceNotifications(data);
            setSelectedDate(data.days.length);
        } catch (err) {
            console.log(err);
        }
    }

    return(
        (deviceInfo)
        ?
            <div className="dashboard">
                <div className="dashboard-card">
                    <DashboardCarousel user />
                </div>
                <div className="stats-container">
                    <div className={`timestamp-container dashboard-card ${deviceInfo.sync.status?'true':'false'}`}>
                        <div className='icon'>
                            {
                                deviceInfo.sync.status ?
                                    <span className="material-icons-outlined">sync</span>
                                :
                                    <span className="material-icons-outlined">sync_problem</span>
                            }
                        </div>
                        <div>
                            <h3>
                                {deviceInfo.sync.status ?
                                    'Your device is synced successfully':
                                    'Please sync your device'
                                }
                            </h3>
                            {/* <h6>Last updated at : </h6> */}
                            {/* <span>{this.convertTimestamp(this.state.deviceTimestampsInfo.data_update.seconds)}</span> */}
                            {/* <h6>Last synced at  : </h6> */}
                            {/* <span>{this.convertTimestamp(this.state.deviceTimestampsInfo.get_data.seconds)}</span> */}
                        </div>
                    </div>
                    <div className={`count-container dashboard-card ${deviceNotifications ? '' : 'loading-skeleton'}`}>
                        {deviceNotifications && selectedDate && 
                            <>
                                <p className="stats-count" id="taken-count">{deviceNotifications.days[selectedDate-1].metrics.pillsTaken}</p>
                                <p className="stats-label">Pills Taken</p>
                                <p className="stats-date">{`${deviceNotifications.days[selectedDate-1].date}`}</p>
                            </>
                        }
                    </div>
                    <div className={`count-container dashboard-card ${deviceNotifications ? '' : 'loading-skeleton'}`}>
                        {deviceNotifications && selectedDate && 
                            <>
                                <p className="stats-count" id="missed-count">{deviceNotifications.days[selectedDate-1].metrics.pillsMissed}</p>
                                <p className="stats-label">Pills Missed</p>
                                <p className="stats-date">{`${deviceNotifications.days[selectedDate-1].date}`}</p>
                            </>
                        }
                    </div>
                </div>
                <div className="dashboard-card">
                    <DashboardNotificationsList deviceNotifications={deviceNotifications} selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
                </div>
                {deviceInfo &&
                    <div className="dashboard-card">
                        <DashboardAlarms deviceInfo={deviceInfo.deviceInfo} deviceTimezone={deviceInfo.deviceTimezone} />
                    </div>
                }
            </div>
        :
            <div className="dashboard">
                Loading...
            </div>
    )
}

export default Dashboard;