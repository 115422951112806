import axios from "axios";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../context/context";

const AxiosInterceptor = (props) => {
    // state
    const { state, dispatch } = useContext(Context);
    const { user } = state;

    // router
    const navigate = useNavigate();

    useEffect(() => {
        if(!user)   navigate('/signin');
        else    window.sessionStorage.setItem('user', JSON.stringify(user));
    }, [user]);

    useEffect(() => {
        axios.interceptors.response.use(
            function (response) {
                // status 2XX code
                return response;
            },
            function (error) {
                // status code outside 2XX
                let res = error.response;
                if(res.status===401 && res.config && !res.config.__isRetryRequest){
                    return new Promise((resolve, reject) => {
                        axios.get('/app-api/user/logout')
                        .then( data => {
                            console.log('401 error > logout');
                            dispatch({type: 'LOGOUT'});
                            window.sessionStorage.removeItem('user');
                            navigate('/signin');
                        })
                        .catch( err => {
                            console.log('AXIOS INTERCEPTORS ERROR', err);
                            reject(err);
                        })
                    })
                }
                return Promise.reject(error);
            }
        );
    });

    return(
        <>
        </>
    )
}

export default AxiosInterceptor;