import { useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Context } from "../../context/context";
import { auth } from "../../firebase/firebase.utils";

import './sign-out.styles.scss';

const SignOut = (props) => {

    //state
    const { state, dispatch } = useContext(Context);

    // router
    const navigate = useNavigate();

    useEffect( () => {
        handleLogout();
    }, []);

    const handleLogout = async() => {
        try {
            await auth.signOut();
            await axios.get('/app-api/user/logout');
            window.sessionStorage.removeItem('user');
            dispatch({type: 'LOGOUT'});
            navigate('/signin');
        } catch (err) {
            console.log(err);
        }
    }

    return(
        <div>
            Logging Out...
        </div>
    )
}

export default SignOut;