import { useContext } from 'react';

import { Context } from '../../context/context';

import "./dashboard-carousel.styles.scss";

const DashboardCarousel = ( props ) => {

    // state
    const { state, dispatch } = useContext(Context);
    const { user } = state;
    
    return(
        <div className="dashboard-carousel">
            <div className="user-name-container">
                <div className="user-name">Hello {user.displayName}</div>
                <div className="intro-line">I hope you taking your pills on time</div>
            </div>
        </div>
    )
}

export default DashboardCarousel;