import { useState, useEffect } from "react";
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import { DateTime } from "luxon";

import { getTimeStringfromAlarmCode } from "../../utilities/utils";

import "./dashboard-alarms.styles.scss";

const DashboardAlarms = ( props ) => {
    const {deviceInfo, deviceTimezone} = props;

    const [isLoading, setIsLoading] = useState(true);
    const [nextAlarm, setNextAlarm] = useState([]);

    useEffect( () => {
        getNextAlarm();
    }, []);

    // router
    const resolved = useResolvedPath('/device/:deviceId/:page');
    const match = useMatch({ path: resolved.pathname, end:true });
    const navigate = useNavigate();

    const navigateToSchedule = () => {navigate(`/device/${match.params.deviceId}/schedule`)};

    const getNextAlarm = () => {
        setIsLoading(true);
        // collect all the alarms
        const allAlarms = [];
        const timeNow = DateTime.now().setZone(deviceTimezone.timezoneValue);
        const timeCodeNow = (timeNow.hour * 60) + timeNow.minute;
        for(let box_index=0; box_index<deviceInfo.length; box_index++){
            const { alarms } = deviceInfo[box_index];
            alarms.forEach( alarm => {
                if(alarm>0){
                    allAlarms.push({
                        time: alarm,
                        box: box_index+1,
                    });
                }
            });
        }
        // sort the alarms based on time
        allAlarms.sort((a,b) => {
            let timeDifferenceA = a.time - timeCodeNow;
            timeDifferenceA = timeDifferenceA < 0 ? timeDifferenceA + 1440 : timeDifferenceA;
            let timeDifferenceB = b.time - timeCodeNow;
            timeDifferenceB = timeDifferenceB < 0 ? timeDifferenceB + 1440 : timeDifferenceB;
            return timeDifferenceA - timeDifferenceB;
        });
        // group the alarms on same time
        const groupedAlarms = [];
        allAlarms.forEach((alarm) => {
            const {time, box} = alarm;
            const [lastAlarm] = groupedAlarms.slice(-1);
            if(lastAlarm && lastAlarm.time === time){
                if (!lastAlarm.box.includes(box))
                    lastAlarm.box.push(box);
                groupedAlarms.pop();
                groupedAlarms.push({...lastAlarm});
            }else{
                groupedAlarms.push({
                    time: time,
                    box: [box],
                });
            }
        });
        setNextAlarm([...groupedAlarms]);
        setIsLoading(false);
    };
    
    return(
        <div className={`dashboard-alarms ${isLoading ? 'loading-skeleton' : ''}`}>
            {!isLoading &&
                <>
                    {
                        nextAlarm.length>0 ?
                            <>
                                <div className="dashboard-alarms-heading">
                                    <div className="next-alarm">
                                        <div className="label">Next Alarm at</div>
                                        <div className="time">{getTimeStringfromAlarmCode(nextAlarm[0].time)}</div>
                                        <div className="pill-names">{nextAlarm[0].box.map( box => deviceInfo[box-1].medicineName).join(', ')}</div>
                                    </div>
                                    <div className="alarm-box">
                                        <div className="medper">
                                            {
                                                deviceInfo.map( (box, index) => (
                                                    <div className={`box ${nextAlarm[0].box.includes(index+1) ? 'highlight' : ''}`} key={index+1}></div>
                                                ))
                                            }
                                        </div>
                                        <div className="box-numbers">Box {nextAlarm[0].box.join(', ')}</div>
                                    </div>
                                </div>
                                {
                                    nextAlarm.length>1 ?
                                        <>
                                            <div className="dashboard-alarms-content">
                                                <div className="upcoming-alarms-heading">Upcoming Alarms</div>
                                                <div className="upcoming-alarms-list">
                                                    {nextAlarm.slice(1,6).map( alarm => 
                                                        <div className="next-alarm-item" key={alarm.time}>
                                                            <div className="time">{getTimeStringfromAlarmCode(alarm.time)}</div>
                                                            <div className="pill-names">{alarm.box.map( box => deviceInfo[box-1].medicineName).join(', ')}</div>
                                                            <div className="box-numbers">Box {alarm.box.join(', ')}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="dashboard-alarms-footer">
                                                <p>Modify your Alarms?</p>
                                                <button onClick={navigateToSchedule}><h4>Go to Schedule</h4></button>
                                            </div>
                                        </>
                                    :
                                        <div className="dashboard-alarms-content expanded">
                                            <p>Add more alarms to your schedule</p>
                                            <button onClick={navigateToSchedule}><h4>Go to Schedule</h4></button>
                                        </div>
                                }
                            </>
                        :
                            <div className="no-alarms">
                                <p>No active alarms in your schedule</p>
                                <button onClick={navigateToSchedule}><h4>Go to Schedule</h4></button>
                            </div>
                    }
                </>
            }
        </div>
    )
}

export default DashboardAlarms;